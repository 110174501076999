import { ref } from '@vue/composition-api'
import router from '@/router'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'
import useSweetAlertNotification from '@/services/notification/useSweetAlert'
// import useSweetAlertNotification from '@/services/notification/useSweetAlert'
import useAuthUser from '@/services/authentification/userService'

export default function useStructureRegister() {
  const loader = ref(false)

  const errors = ref('')
  const { errorToast, notificationToast } = useAlertNotification()
  const { getUserAuth } = useAuthUser()

  const handleRegister = async data => {
    try {
      loader.value = true
      const response = await axiosClient.post('/register-structure', data)
      if (response.data.success === true) {
        await getUserAuth(response.data.data.token)
        loader.value = false
        notificationToast('Compte en cours de validation', 'Veuillez patienter pendant qu\'un administrateur s\'assure de la qualité de votre compte')
        router.push({ name: 'auth-login' })
      }
    } catch (error) {
      loader.value = false
      errors.value = error.response.data.errors
      const createUserErrors = error.response.data.errors
      Object.keys(createUserErrors).forEach(key => {
        errorToast('Oups! Erreur', createUserErrors[key][0])
      })
    }
  }

  return {
    errors,
    loader,
    handleRegister,
  }
}
